<template>
<div id="app">


            <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
                <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
                    <v-layout column>
                        <div>
                            <strong>{{ snackbartest.title }}</strong>
                        </div>
                        <div>{{ snackbartest.text }}</div>
                    </v-layout>
                </v-layout>
                <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
                    <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>

    <v-card max-width="700" outlined>
        <v-list-item three-line>
            <v-list-item-content>

                <v-list-item-title class="text-h5 mb-2">
                    Reference du projet : {{ this.reference_matricule }}
                </v-list-item-title>
                <v-list-item-subtitle>Detail : {{ this.information }}</v-list-item-subtitle>
            </v-list-item-content>

        </v-list-item>

        <v-card-actions>
            <v-btn outlined rounded dark color="green" @click="dialogmatricule=true">
                Entrer Matricule Institution
            </v-btn>
        </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogmatricule" max-width="500px">

        <v-card>
            <v-card-title>
                <span class="text-h5">Formulaire de verification</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field v-model="matricule" label="Reference du souscription*" dense outlined required></v-text-field>
                    </v-form>

                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialogmatricule=false">
                    Fermer
                </v-btn>
                <v-btn color="green darken-1" text @click="this.read_matricule_All">
                    Valider
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">

        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Liste des infos collectes</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">

                    <v-snackbar v-model="snackbar" :multi-line="multiLine">
                        {{ text }}

                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                Fermer
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                            Nouveau
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                       <v-text-field v-model="reference_matricule" label="Reference du projet*" disabled dense outlined required></v-text-field>
                                         <v-row>
                                        <v-col>
                                            <v-select :items="listannee" v-model="selectannee" dense item-value="libelle" outlined label="Année de collecte">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.libelle }}
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col>

                                            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="dateFormatted" label="Date" hint="MM/DD/YYYY format" persistent-hint prepend-icon="mdi-calendar" v-bind="attrs" @blur="date = parseDate(dateFormatted)" v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                      <v-select :items="list_mesure" v-model="selectmesure" dense item-value="libelle" outlined label="Mesure">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.libelle }}
                                                </template>
                                            </v-select>   
                                    <v-textarea v-model="observation" outlined name="input-7-4" label="Observation">
                                    </v-textarea>

                                </v-form>

                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">
                                Fermer
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                Enregistrer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.souscriptions.indicateurodd_extrat`]="{ item }">
            {{ item.souscriptions.indicateurodd_extrat.code }} - {{ item.souscriptions.indicateurodd_extrat.libelle }}
        </template>

        <template v-slot:[`item.validation`]="{ item }">
            <v-chip :color="getColor(item.validation)" dark>
                {{ getvalidation(item.validation) }}
            </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" color="green" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon color="red" class="mr-2" small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
            <v-icon color="bleu" class="mr-2" small @click="detail_collecte(item)">
                mdi-database-sync
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Actualiser la liste
            </v-btn>
        </template>
    </v-data-table>

</div>
</template>

<script>
import api from '../../../../serviceApi/apiService'

export default {
    props: {
        id: "0",
        idcollecte: "",
        libelleindicateur: '',
        annee: '',
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        multiLine: true,
        snackbartest: false,
        textmessage: '',
        
        snackbar: false,
        snackbars: false,
        text: `message`,
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        dialogmatricule: false,
        headers: [{
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '50px'
            },
            {
                text: 'ID',
                value: 'id',
                divider: true,
                width: '10px'
            },
            {
                text: 'Date',
                align: 'start',
                sortable: false,
                value: 'dates',
                divider: true,
                width: '50px'
            },
            {
                text: 'Annee',
                value: 'annee_collecte',
                divider: true,
                width: '20px'
            },
            {
                text: 'Code de souscription',
                value: 'souscriptions.code',
                divider: true,
                width: '100px'
            },
            {
                text: 'Indicateur Odd',
                align: 'start',
                value: 'souscriptions.indicateurodd_extrat',
                divider: true,
                width: '250px'
            },
              {
                text: 'Mesure',
                value: 'mesure',
                divider: true,
                width: '100px'
            },
            {
                text: 'Etat',
                align: 'start',
                sortable: false,
                value: 'validation',
                divider: true,
                width: '10'
            },
            {
                text: 'Observation',
                align: 'start',
                sortable: false,
                value: 'observation',
                divider: true,
                width: '100'
            },

        ],
        formTitle: 'Formulaire de collecte',
        list: [],
        
        listannee: [],
        listmodalite2: [],
        listmodalite: [],
        list_mesure: [],

        idindicateur: '0',

        selectcible: '',
        selectmesure: '',

        ref_projet: '',
        observation: '',
        valider: '0',
        selectannee: '',
        valid: true,
        dates: '',
        editedIndex: -1,
        textetat: false,
        dateFormatted: null,
        messages: '',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        //dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

        menu1: false,

        reference_matricule: '',
        information: '',
        id: '',

    }),

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
    },

    watch: {
        date(val) {
            this.dateFormatted = this.formatDate(this.date)
        },
    },

    methods: {
        //=====Affichages  liste des odds==========
        readAll: async function () {
            let fd = new FormData();
            fd.append('code', this.reference_matricule);
            const data = await api.createUpdatedata('info-collecte-data/all-only-info-collecte', fd);
            this.list = data.list;

        },

        readanneeAll: async function () {
            const data = await api.readAll('parametre/all-annee');
            this.listannee = data.list;
            this.list_mesure = data.list_mesure;
        },

        readModaliteAll: async function () {
            const data = await api.readAll('parametre/all-modalite-odd');
            this.listmodalite2 = data.list;
        },
        read_matricule_All: async function () {
            // const data = await api.readAll('souscription/verifie-souscription-odd-extrat');
            let fd = new FormData();
            fd.append('code', this.matricule);
            const data = await api.createUpdatedata('info-collecte-data/verifie-souscription', fd);
            this.list = data.list;
            this.listinfo = data.list_info;
            if (data.status == 200) {
                this.information = this.listinfo[0].indicateurodd_extrat.code + ' - ' + this.listinfo[0].indicateurodd_extrat.libelle;
                this.reference_matricule = this.listinfo[0].code;
                this.dialogmatricule = false;
            } else {
                this.reference_matricule = 'xxxxxxx';
                this.information = 'xxxxxxxxx';
            }

        },
        detail_collecte(item){
              //this.$emit('libelle_indicateur', "Indicateur : " + item.souscriptions.indicateurodd.code + "-" + item.souscriptions.indicateurodd.libelle);
              console.log('indicateur',item.souscriptions.indicateurodd_extrat.id);
            this.$router.push({ name: 'operation-collecte-odd', params: {idcollectes: item.id, id_indicateur_extrat: item.souscriptions.indicateurodd_extrat.id}});
          /*  this.$router.push('operation-collecte-odd',{
                params:{idcollectes:this.id},
            });*/
        },
        deleteData: async function () {
            let fd = new FormData();
            fd.append('id', this.id_odds);
            const res = await api.createUpdatedata('souscription/delete', fd);
            this.messagesligne(res);
        },

        createUpdatedata: async function () {
            let fd = new FormData();
            fd.append('code', this.reference_matricule);
            fd.append('observation', this.observation);
            fd.append('annee_collecte', this.selectannee);
            fd.append('dates', this.date);
            fd.append('mesure', this.selectmesure);

            if (this.textetat) {

                const res = await api.createUpdatedata('info-collecte-data/update-info-collecte/' + this.id, fd);
               if(res.status == 200){
                  this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    }
               }else{
                   this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    }
               }


            } else {
                const res = await api.createUpdatedata('info-collecte-data/add-info-collecte', fd);
                 if(res.status == 200){
                  this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    }
              }else{
                  this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    }
              }
            }

            this.clear();
            this.readAll();
        },
        initialize() {
            this.readAll();
        },

        nouveau() {
            this.textetat = false;
        
        },

        clear() {
            this.textetat = false;
            this.observation='';
        
        },
        deleteItem(item) {
            this.id_odds = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.id = item.id;
            this.reference_matricule = item.souscriptions.code;
            this.selectannee=item.annee_collecte;
            this.dates=item.dates;
            this.textetat = true;
            this.dialog = true;
        },

        chargeItem(item) {
            this.idindicateur = item.souscriptions.indicateurodd.id;
            this.$emit('libelle_indicateur', "Indicateur : " + item.souscriptions.indicateurodd.code + "-" + item.souscriptions.indicateurodd.libelle);
            this.$emit('reference_projet', "Reference Projet : " + item.souscriptions.code);
            this.$emit('idcollecte', item.id);
            this.$emit('idindicateur', item.souscriptions.indicateurodd.id);
            this.fuseSearchmodalite();
            this.$emit('listemodalite', this.listmodalite);
            // console.log(this.listmodalite);

        },
        fuseSearchmodalite() {
            this.listmodalite = [];
            this.listmodalite = this.listmodalite2.filter(this.parcouremodalite);
        },
        parcouremodalite: function (obj) {
            if (obj.id_indicateurodds == this.idindicateur) {
                return true;
            } else {
                return false;
            }
        },

        getColor(validation) {
            if (validation == "0") return 'red'
            else if (validation == '1') return 'green'
            else return 'red'
        },

        getvalidation(validation) {
            if (validation == "0") return 'NON'
            else if (validation == '1') return 'OUI'
            else return 'NON'
        },

        deleteItemConfirm() {
            this.deleteData();
            this.readAll();
            this.dialogDelete = false;
        },

        save() {
            if (this.reference_matricule == '') {
                this.text = "Veuillez Inserer la reference de souscription";
                this.snackbar = true;
                return;
            }

            this.createUpdatedata();

        },

        parcoure: function filtrerParID(obj) {
            if (obj.id_cibles == this.selectcible) {
                console.log(obj.libelle);
                return true;
            } else {

                return false;
            }
        },

        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

    },
    mounted() {
        this.readAll();
         this.readanneeAll();
        // this.readModaliteAll();
    },

}
</script>
